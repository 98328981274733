@import '~@aws-amplify/ui-angular/theme.css';
$lg: 100px;

.cursor-pointer {
  cursor: pointer;
}

.navbar.topnav-menu {
  height: unset;
}

.navbar-logo {
  max-height: 150px;

}

.amplify-heading {
  color: #8c52ff;
}

@media (max-width: 991.98px) {
  .navbar-logo {
    max-height: 90px;
  }

  .navbar-collapse {
    max-height: 50vh;
    min-height: 50vh;
    overflow-y: auto;

  }


}


#instafeed {
  max-width: 375px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    display: none;
    align-items: center;
    position: relative;
    width: 50%;
    background: white;

    @media only screen and (min-width: $lg) {
      width: 25%;
    }

    img {
      display: block;
      width: 100px;
      max-width: 100px;
      height: 100px
    }
  }
  a:nth-child(-n + 3) {
    display: flex;
  }

}